import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import AntSwitch from '../../../../../components/AntSwitch';
import FarmSelect from '../../../../farms/containers/FarmSelect';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import { fetchLabels } from '../../../../labels/labelsSlice';
import {
  selectLabelsMap,
  selectLoading as selectLabelsLoading,
} from '../../../../labels/labelsSelectors';
import {
  setLabelsExpanded,
  updateFieldsListParams,
} from '../../fieldsListSlice';
import type { AutocompleteItem } from '../../../autocomplete/types/autocomplete';
import type { TransformedFarm } from '../../../../farms/types/farm';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { transformToFilterUpdates } from '../../helpers/functions/filters';
import LabelsSelect from '../../../../../components/LabelsSelect';
import { updateSearchParam } from '../../../../../helpers/navigation';
import { FieldsListSearchParam } from '../../helpers/constants/filters';
import useFieldsListParams from '../../hooks/useFieldsListParams';

import './index.scss';

export default function FieldsListFilter({
  withLabelsExpandSwitcher = true,
  withBookmarkableUrl = false,
  withCreateFarm,
}: {
  withLabelsExpandSwitcher?: boolean,
  withBookmarkableUrl?: boolean,
  withCreateFarm?: boolean,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  // @ts-expect-error
  const labelsMap = useAppSelector(selectLabelsMap);
  const labelsLoading = useAppSelector(selectLabelsLoading);
  const fieldsListParams = useFieldsListParams();

  const handleGetLabelsOptions = () => {
    dispatch(fetchLabels());
  };

  const handleFilterChange = (value: AutocompleteItem[]) => {
    const filterUpdates = transformToFilterUpdates(value, labelsMap);

    sendFeatureUsage('fields', 'search', {
      labels: (filterUpdates.labels || []).length,
      name: filterUpdates.name != null,
    });

    if (withBookmarkableUrl) {
      updateSearchParam(location, history, [
        {
          name: FieldsListSearchParam.autocomplete,
          value: value?.length
            ? encodeURIComponent(JSON.stringify(value))
            : '',
        },
      ]);
    }

    dispatch(updateFieldsListParams({ filter: { autocompleteValue: value } }));
  };

  const handleFarmChange = (value: TransformedFarm | null) => {
    const farmUuid = value?.uuid ?? '';

    if (withBookmarkableUrl) {
      updateSearchParam(
        location,
        history,
        [{ name: FieldsListSearchParam.farm, value: farmUuid }],
      );
    }

    dispatch(updateFieldsListParams({
      filter: { farmUuid },
    }));
  };

  const handleLabelsSwitchClick = (checked: boolean) => {
    if (withBookmarkableUrl) {
      updateSearchParam(
        location,
        history,
        [{ name: FieldsListSearchParam.labelsExpanded, value: checked.toString() }],
      );
    }

    dispatch(setLabelsExpanded(checked));
  };

  return (
    <div className="fields-list-filter">
      <FarmSelect
        withCreateFarm={withCreateFarm}
        selectedFarmUuid={fieldsListParams?.farmUuid}
        loading={!fieldsListParams}
        disabled={!fieldsListParams}
        onFarmChange={handleFarmChange}
      />
      <LabelsSelect
        limitTags={4}
        placeholder={t('general.controls.filter-by-label-or-name')}
        placeholderWithValue={t('field-profiler.tools-panel.add-filter')}
        searchLabel={t('field-profiler.tools-panel.search')}
        allValuesLabel={t('field-profiler.tools-panel.all-values')}
        emptyLabel={t('field-profiler.tools-panel.empty')}
        loading={labelsLoading}
        disabled={labelsLoading}
        options={labelsMap}
        value={fieldsListParams?.autocompleteValue ?? []}
        onChange={handleFilterChange}
        onGetOptions={handleGetLabelsOptions}
      />
      {
        withLabelsExpandSwitcher
        && (
          <div className="fields-list-filter__switch-wrapper">
            <AntSwitch
              isChecked={fieldsListParams?.labelsExpanded}
              label={t('field-profiler.tools-panel.expand-all-labels')}
              onValueChange={handleLabelsSwitchClick}
            />
          </div>
        )
      }
    </div>
  );
}
