import isBefore from 'date-fns/isBefore';

import { ApplicationStatus } from '../constants/application';
import { getEndDate } from '../../../../user/helpers/functions/order';

export const getApplicationStatus = (paidOrder, credits, hasGroupPermission) => {
  const paidOrderEndDate = getEndDate(paidOrder);

  if (!paidOrderEndDate || !hasGroupPermission) {
    return ApplicationStatus.blocked;
  }

  if (!isBefore(new Date(), paidOrderEndDate)) {
    return ApplicationStatus.subscriptionAvailable;
  }

  if (credits <= 0) {
    return ApplicationStatus.subscriptionAvailable;
  }

  return ApplicationStatus.available;
};
