import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import { PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import Panel from '../features/ui/planSubscription/containers/Panel';
import useDidMount from '../hooks/useDidMount';
import BaseTemplate from './BaseTemplate.jsx';
import useSubscriptionAvailable from '../hooks/useSubscriptionAvailable';
import NoAccessPanel from '../features/ui/applicationShell/components/NoAccessPanel';
import { getBrandFullName } from '../helpers/functions/utils/appConfig';

export default function WelcomeScreen() {
  const { t } = useTranslation();
  const subscriptionAvailable = useSubscriptionAvailable();
  const brandName = getBrandFullName();

  useDidMount(() => {
    document.title = getDocumentTitle(t('welcome-screen.header', { brandName }));
  });

  const content = subscriptionAvailable ? <Panel key="panel" /> : <NoAccessPanel />;

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.welcomeScreen}
          headerProps={{ text: t('welcome-screen.header', { brandName }) }}
        >
          {content}
        </BaseTemplate>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}
