import parse from 'date-fns/parse';
import {
  differenceInHours,
  endOfDay,
  isBefore,
  addMonths,
} from 'date-fns';

import {
  OrderStatus,
  PricingPlan,
} from '../constants/order';
import { PaymentMethod } from '../constants/paymentMethods';

export const getEndDate = (order) => {
  let result = null;

  if (order?.packageInfo?.endDate) {
    result = parse(order.packageInfo.endDate, 'yyyy-MM-dd', new Date());
  } else if (order?.packageInfo?.startDate && order.billingPeriodInMonths) {
    result = addMonths(
      parse(order.packageInfo.startDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  } else if (order?.orderDate && order.billingPeriodInMonths) {
    result = addMonths(
      parse(order.orderDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  }

  return result;
};

export const getLastPaid = (orders = []) => {
  return orders.find((order) => {
    return order.status === OrderStatus.InvoicePaid;
  });
};

export const isEditable = (orderStatus) => {
  return orderStatus === 'DRAFT' || orderStatus === 'PACKAGE_ORDERED';
};

export const hasStripePayment = (orders) => {
  return orders.some((order) => order.paymentMethod === PaymentMethod.stripe);
};

export const isFreeTrialPlan = (order) => {
  return order?.plan === PricingPlan.FreeTrial;
};

export const getOrderDaysLeft = (orderEndDate) => {
  if (!orderEndDate) {
    return 0;
  }

  const today = new Date();
  const endOfOrderEndDate = endOfDay(orderEndDate);

  if (isBefore(endOfOrderEndDate, today)) {
    return 0;
  }

  const diffInHours = differenceInHours(endOfOrderEndDate, today);
  return Math.ceil(diffInHours / 24);
};
