import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import PricingRedirector from '../routes/redirectors/Pricing';
import Stepper from '../features/ui/jdExport/containers/ExportDataStepper';
import Legend from '../features/ui/jdExport/containers/ExportDataLegend';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import { getDocumentTitle } from '../helpers';
import useDidMount from '../hooks/useDidMount';
import { resetStateExportJohnDeere } from '../features/ui/jdExport/jdExportSlice';
import { isJohnDeereExportWorkflowEnabled } from '../helpers/functions/utils/appConfig';

const ExportDataJohnDeere = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useDidMount(() => {
    document.title = getDocumentTitle(t('export-data-john-deere.title'));

    return () => {
      dispatch(resetStateExportJohnDeere());
    };
  });

  if (!isJohnDeereExportWorkflowEnabled()) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.fields)} />
    );
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            route={PAGES_ROOTS.exportDataJohnDeere}
            headerProps={{
              text: t('export-data-john-deere.title'),
            }}
          >
            <Stepper key="panel" />
            <Legend key="legend" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default ExportDataJohnDeere;
