import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import PricingRedirector from '../routes/redirectors/Pricing';
import SmartSamplingRedirector from '../routes/redirectors/SmartSampling';
import Table from '../features/ui/smartSampling/containers/Table';
import ToolsPanel from '../features/ui/smartSampling/containers/ToolsPanel';
import BackdropLoading from '../components/Loading/BackdropLoading';
import { subscription } from '../features/ui/smartSampling/smartSamplingSlice';
import { getDocumentTitle } from '../helpers';
import { PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';
import useDidMount from '../hooks/useDidMount';

const SmartSampling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.smart-sampling'));
  });

  const subscriptionCallback = (parsedEvent) => {
    dispatch(subscription(parsedEvent));
  };

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <SmartSamplingRedirector>
          <PricingRedirector>
            <BaseTemplate
              route={PAGES_ROOTS.smartSampling}
              subscriptionCallback={subscriptionCallback}
              headerProps={{
                text: t('general.navigation.smart-sampling'),
              }}
            >
              <ToolsPanel key="tools-panel" />
              <Table key="panel" />
              { isFarmsFetching && <BackdropLoading key="loading" />}
            </BaseTemplate>
          </PricingRedirector>
        </SmartSamplingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default SmartSampling;
