import React, { ReactElement, useEffect } from 'react';

import BackdropLoading from '../../../components/Loading/BackdropLoading';
import { selectApplicationStatus } from '../../../features/ui/applicationShell/applicationShellSelectors';
import { ApplicationStatus } from '../../../features/ui/applicationShell/helpers/constants/application';
import { selectIsLoaded, selectPricingData } from '../../../features/user/userSelectors';
import { goToPricingApp } from '../../../helpers/navigation';
import { useAppSelector } from '../../../app/store/helpers/functions';

export default function PricingRedirector({ children }: {
  children: ReactElement;
}) {
  const isUserLoaded = useAppSelector(selectIsLoaded);
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const pricingUserData = useAppSelector(selectPricingData);

  useEffect(() => {
    if (applicationStatus === ApplicationStatus.available) {
      return;
    }

    const params: {
      id?: string;
      currency?: string;
    } = {};

    if (pricingUserData?.stripeCustomerId) {
      params.id = pricingUserData?.stripeCustomerId;
    }

    if (pricingUserData?.currency) {
      params.currency = pricingUserData?.currency;
    }

    goToPricingApp(params);
  }, [applicationStatus, pricingUserData]);

  if (!isUserLoaded) {
    return <BackdropLoading />;
  }

  return children;
}
