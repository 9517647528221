import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import {
  selectAreaUnit,
  selectEmail,
  selectLanguage,
  selectMaxArea,
  selectReachedAreaLimit,
  selectUuid,
} from '../../user/userSelectors';
import {
  getUserData,
  setupUserIdentity,
} from '../../user/userSlice';
import { warningNotify } from '../../notifications/helpers/functions/notify';
import { getI18nAreaAmount } from '../../user/helpers/functions/user';
import { getPricingAppUrl } from '../../../helpers/functions/utils/vendorConfigs/pricing';
import { initialize as initializeProductFruits } from './helpers/functions/productFruits';
import { convertNumberToFormattedString } from '../../../helpers/markup';

let userDataFetcherResolve;
let userDataFetcher = new Promise((resolve) => {
  userDataFetcherResolve = resolve;
});

export const getUserDataFetcher = () => userDataFetcher;

export const setupApp = () => async (dispatch, getState) => {
  // useSubscriptionAvailable hook relies on userData to be available after app init.
  userDataFetcher = dispatch(getUserData())
    .then(({ payload }) => {
      if (userDataFetcherResolve) {
        userDataFetcherResolve();
        userDataFetcherResolve = null;
        return payload.data;
      }
    });

  // always fetch userData
  try {
    await userDataFetcher;
  } catch (error) {
    console.error('Unable to fetch user data.', error);

    return;
  }

  const state = getState();
  const reachedAreaLimit = selectReachedAreaLimit(state);
  const userUuid = selectUuid(state);
  const userEmail = selectEmail(state);
  const language = selectLanguage(state);

  initializeProductFruits({
    userEmail,
    userUuid,
    language,
  });

  await i18n.changeLanguage(selectLanguage(state));

  dispatch(setupUserIdentity());

  if (reachedAreaLimit) {
    dispatch(areaLimitReachedWarningNotify());
  }
};

export const areaLimitReachedWarningNotify = () => (_dispatch, getState) => {
  const state = getState();
  const maxArea = selectMaxArea(state);
  const areaUnit = selectAreaUnit(state);
  const areaUnitLabel = getI18nAreaAmount(areaUnit).toLowerCase();
  const maxAreaLabel = `${convertNumberToFormattedString(maxArea.toFixed(2))}`;

  warningNotify({
    messageElement: (
      <Trans
        i18nKey="general.notifications.max-area-reached"
        resultArea
      >
        text {{ area: maxAreaLabel }} {{ areaUnit: areaUnitLabel }} text
        <a href={getPricingAppUrl()} className="link" target="_blank">link</a>
        text
      </Trans>
    ),
  });
};
