import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { POPUPS } from '../../helpers/constants/popups';
import { closePopup } from '../../popupsSlice';
import DeleteFields from '../../../../../components/Popups/DeleteFields';
import RenameEntity from '../../../../../components/Popups/RenameEntity';
import DeleteEntity from '../../../../../components/Popups/DeleteEntity';
import EditTextField from '../../../../../components/Popups/EditTextField';
import ExportToJohnDeerePopup from '../../../jdExport/components/ExportToJohnDeerePopup';
import ExportAsWorkPlanPopup from '../../../jdWorkPlan/containers/ExportAsWorkPlanPopup';
import ViewDetailsJohnDeere from '../../../jdImport/components/ViewDetailsPopup';
import ImportSettingsJohnDeere from '../../../jdImport/components/ImportSettingsPopup';
import RemoveLabelsPopup from '../../../../labels/components/RemoveLabelsPopup';
import ColorSchema from '../../../userSettings/components/ColorSchemaPopup';
import DeleteColorSchema from '../../../userSettings/components/DeleteColorSchemaPopup';
import UploadClosePopup from '../../../uploadData/components/UploadClosePopup';
import RatesPreviewPopup from '../../../../../components/Popups/RatesPreview';
import ExportZonesMap from '../../../../../components/Popups/ExportData/ExportZonesMap';
import ExportSourceData from '../../../../../components/Popups/ExportData/ExportSourceData';
import ExportEquationMap from '../../../../../components/Popups/ExportData/ExportEquationMap';
import TextFieldPopup from '../../../../../components/Popups/TextFieldPopup';
import PinPhotosPopup from '../../../../pins/components/PinPhotosPopup';
import RemovePin from '../../../../pins/components/RemovePinPopup';
import { selectPopups } from '../../popupsSelectors';
import AddLabelsPopup from '../../../../labels/containers/AddLabelsPopup';
import ManageLabels from '../../../../labels/containers/ManageLabels';
import CreateLabelPopup from '../../../../labels/containers/CreateLabelPopup';
import PinDetailsPopup from '../../../../pins/containers/PinDetailsPopup';
import CreateFarm from '../../../../farms/containers/CreateFarmPopup';
import UploadFilesPopup from '../../../uploadData/containers/UploadFilesPopup';
import SaveZonesMapPopup from '../../../../createAnalysis/containers/SaveZonesMapPopup';
import ChangeBackgroundLayer from '../../../overlayLayers/containers/ChangeBackgroundLayer';
import OrganizationSettingsPopup from '../../../userOrganizations/containers/OrganizationSettingsPopup';
import InviteUserPopup from '../../../userOrganizations/containers/InviteUserPopup';
import ShareFarmsPopup from '../../../userFarms/containers/ShareFarmsPopup';
import ShareFarmsOrgPopup from '../../../userFarms/containers/ShareFarmsOrgPopup';
import ShareFarmsExternalPopup from '../../../userFarms/containers/ShareFarmsExternalPopup';
import StopFarmShare from '../../../userFarms/containers/StopFarmSharePopup';
import Save3dMap from '../../../createThreeDMap/containers/Popups/Save3dMap';
import SaveEquationMap from '../../../createEquationBasedAnalysis/containers/Popups/SaveEquationMap';
import AddDataLayers from '../../../compareLayers/containers/Popups/AddDataLayers';
import SelectDataVariablePopup from '../../../createEquationBasedAnalysis/containers/Popups/SelectDataVariable';
import EquationTestRun from '../../../../equations/containers/Popups/EquationTestRun';
import CreateEquation from '../../../../equations/containers/Popups/CreateEquation';
import UpdateEquation from '../../../../equations/containers/Popups/UpdateEquation';
import PlanetMetadataStatusPopup from '../../../../planetMetadata/containers/PlanetMetadataStatusPopup';
import PlanetMetadataRequestPopup from '../../../fieldWorkflow/containers/PlanetMetadataRequestPopup';
import UnsavedChangesPopup from '../../../createField/components/UnsavedChangesPopup';
import CleanCalibrateYieldPopup from '../../../cleanCalibrate/containers/CleanCalibrateYieldPopup';
import ExportBoundaryToJohnDeerePopup from '../../../jdExport/components/ExportBoundaryToJohnDeerePopup';
import ExportFieldsToJohnDeerePopup from '../../../jdExport/components/ExportFieldsToJohnDeerePopup';
import ReportIssuePopup from '../../../applicationShell/containers/ReportIssuePopup';
import CreateFieldPopup from '../../../createField/containers/CreateFieldPopup';
import ClonePolygonsPopup from '../../../drawManuallyWorkflow/containers/Popups/ClonePolygons';
import AssignVariablePopup from '../../../createBatchEquationBasedAnalysis/containers/Popups/AssignVariable';
import FinishGenerationPopup from '../../../createBatchEquationBasedAnalysis/containers/Popups/FinishGeneration';

const PopupsContainer = () => {
  const dispatch = useDispatch();
  const popups = useSelector(selectPopups);

  return popups.map((p, ind) => {
    const popup = {
      ...p,
      key: ind,
      onCancel: (...args) => {
        if (p.onCancel) {
          p.onCancel(...args);
        }
        dispatch(closePopup(p.id));
      },
      onConfirm: (...args) => {
        if (p.onConfirm) {
          p.onConfirm(...args);
        }
        dispatch(closePopup(p.id));
      },
    };

    switch (p.type) {
      case 'delete-fields':
        return (
          <DeleteFields
            {...popup}
          />
        );
      case 'save-zones-map':
        return (
          <SaveZonesMapPopup
            {...popup}
          />
        );
      case 'save-3d-map':
        return (
          <Save3dMap
            {...popup}
          />
        );
      case 'save-equation-map':
        return (
          <SaveEquationMap
            {...popup}
          />
        );
      case 'manage-labels':
        return (
          <ManageLabels {...popup} />
        );
      case 'create-label':
        return (
          <CreateLabelPopup {...popup} />
        );
      case 'rename-field':
        return (
          <RenameEntity
            i18nKey="field"
            {...popup}
          />
        );
      case 'rename-zones-map':
        return (
          <RenameEntity
            i18nKey="zones-map"
            {...popup}
          />
        );
      case 'rename-dataset':
        return (
          <RenameEntity
            i18nKey="dataset"
            {...popup}
          />
        );
      case 'rename-3d-map':
        return (
          <RenameEntity
            i18nKey="3d-map"
            {...popup}
          />
        );
      case 'rename-farm':
        return (
          <RenameEntity
            i18nKey="farm"
            {...popup}
          />
        );
      case 'rename-equation-map':
        return (
          <RenameEntity
            i18nKey="equation-map"
            {...popup}
          />
        );
      case 'edit-source-url-equation':
        return (
          <EditTextField
            i18nKey="equation-source-url"
            {...popup}
          />
        );
      case 'delete-field':
        return (
          <DeleteEntity
            i18nKey="field"
            {...popup}
          />
        );
      case 'delete-zones-map':
        return (
          <DeleteEntity
            i18nKey="zones-map"
            {...popup}
          />
        );
      case 'delete-user-from-organization':
        return (
          <DeleteEntity
            i18nKey="user"
            {...popup}
          />
        );
      case 'delete-dataset':
        return (
          <DeleteEntity
            i18nKey="dataset"
            {...popup}
          />
        );
      case 'delete-3d-map':
        return (
          <DeleteEntity
            i18nKey="3d-map"
            {...popup}
          />
        );
      case 'delete-farm':
        return (
          <DeleteEntity
            i18nKey="farm"
            {...popup}
          />
        );
      case 'delete-shared-farm':
        return (
          <DeleteEntity
            i18nKey="shared-farm"
            i18nDescriptionKey="shared-farm"
            {...popup}
          />
        );
      case 'delete-equation-map':
        return (
          <DeleteEntity
            i18nKey="equation-map"
            {...popup}
          />
        );
      case 'delete-equation':
        return (
          <DeleteEntity
            i18nKey="equation"
            {...popup}
          />
        );
      case 'export-to-john-deere':
        return (
          <ExportToJohnDeerePopup
            {...popup}
          />
        );
      case 'export-boundary-to-john-deere':
        return (
          <ExportBoundaryToJohnDeerePopup {...popup} />
        );
      case 'view-details-john-deere':
        return (
          <ViewDetailsJohnDeere
            {...popup}
          />
        );
      case 'import-settings-john-deere':
        return (
          <ImportSettingsJohnDeere
            {...popup}
          />
        );
      case 'add-labels':
        return (
          <AddLabelsPopup {...popup} />
        );
      case 'remove-labels':
        return (
          <RemoveLabelsPopup
            {...popup}
          />
        );
      case 'unsaved-changes':
        return (
          <UnsavedChangesPopup {...popup} />
        );
      case POPUPS.addDataLayer:
        return (
          <AddDataLayers
            {...popup}
          />
        );
      case POPUPS.selectDataVariable:
        return (
          <SelectDataVariablePopup
            {...popup}
          />
        );
      case POPUPS.colorSchema:
        return (
          <ColorSchema
            {...popup}
          />
        );
      case POPUPS.deleteColorSchema:
        return (
          <DeleteColorSchema
            {...popup}
          />
        );
      case 'upload-files':
        return (
          <UploadFilesPopup
            {...popup}
          />
        );
      case POPUPS.closeUpload:
        return (
          <UploadClosePopup
            {...popup}
          />
        );
      case 'create-farm':
        return (
          <CreateFarm
            {...popup}
          />
        );
      case 'rates-preview':
        return (
          <RatesPreviewPopup
            {...popup}
          />
        );
      case POPUPS.assignVariable:
        return (
          <AssignVariablePopup
            {...popup}
          />
        );
      case POPUPS.changeBackgroundLayer:
        return (
          <ChangeBackgroundLayer {...popup} />
        );
      case POPUPS.copyPolygons:
        return (
          <ClonePolygonsPopup {...popup} />
        );
      case 'export-zones-map':
        return (
          <ExportZonesMap
            {...popup}
          />
        );
      case POPUPS.exportEquationMap:
        return (
          <ExportEquationMap {...popup} />
        );
      case POPUPS.exportSourceData:
        return (
          <ExportSourceData
            {...popup}
          />
        );
      case 'name-zones-map':
        return (
          <TextFieldPopup {...popup} />
        );
      case 'pin-details':
        return (
          <PinDetailsPopup {...popup} />
        );
      case 'organization-settings':
        return (
          <OrganizationSettingsPopup {...popup} />
        );
      case 'invite-user':
        return (
          <InviteUserPopup {...popup} />
        );
      case 'share-farms':
        return (
          <ShareFarmsPopup {...popup} />
        );
      case 'share-farms-org':
        return (
          <ShareFarmsOrgPopup {...popup} />
        );
      case 'share-farms-external':
        return (
          <ShareFarmsExternalPopup {...popup} />
        );
      case 'stop-farm-share':
        return (
          <StopFarmShare {...popup} />
        );
      case 'pin-photos':
        return (
          <PinPhotosPopup {...popup} />
        );
      case 'remove-pin':
        return (
          <RemovePin {...popup} />
        );
      case POPUPS.equationTestRun:
        return (
          <EquationTestRun {...popup} />
        );
      case POPUPS.createEquation:
        return (
          <CreateEquation {...popup} />
        );
      case POPUPS.updateEquation:
        return (
          <UpdateEquation {...popup} />
        );
      case POPUPS.planetMetadataStatus:
        return (
          <PlanetMetadataStatusPopup {...popup} />
        );
      case POPUPS.planetMetadataRequest:
        return (
          <PlanetMetadataRequestPopup {...popup} />
        );
      case POPUPS.cleanCalibrateYield:
        return (
          <CleanCalibrateYieldPopup {...popup} />
        );
      case POPUPS.exportFieldsToJD:
        return (
          <ExportFieldsToJohnDeerePopup {...popup} />
        );
      case POPUPS.reportIssue:
        return (
          <ReportIssuePopup {...popup} />
        );
      case POPUPS.createFieldPopup:
        return (
          <CreateFieldPopup {...popup} />
        );
      case POPUPS.finishGeneration:
        return (
          <FinishGenerationPopup {...popup} />
        );
      case POPUPS.exportToJohnDeereAsWorkPlan:
        return (
          <ExportAsWorkPlanPopup
            {...popup}
          />
        );
      default:
        return null;
    }
  });
};

export default PopupsContainer;
