import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../app/store/helpers/functions';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { Country } from '../types/api';

export default function useSupportedCountriesList() {
  const [countries, setSupportedCountries] = useState<Country[]>([]);
  const [isLoading, setSupportedCountriesLoading] = useState(false);

  const dispatch = useAppDispatch();

  const loadCountriesList = async () => {
    const response = await fetch('/assets/countries.json');
    const countriesList = await response.json();
    return countriesList;
  };

  useEffect(() => {
    (async () => {
      setSupportedCountriesLoading(true);

      try {
        const countriesList = await loadCountriesList();
        setSupportedCountries(countriesList);
      } catch (error) {
        errorNotify({
          error: new CustomError(
            '[Countries] Unable to fetch the list of countries.',
            { cause: error },
          ),
          dispatch,
        });
      } finally {
        setSupportedCountriesLoading(false);
      }
    })();
  }, [dispatch]);

  return {
    countries,
    isLoading,
  };
}
