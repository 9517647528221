import React, { useCallback } from 'react';

import WelcomePanelComponent from '../../components/WelcomePanel';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import { getUserData } from '../../../../user/userSlice';
import { selectApplicationStatus } from '../../../applicationShell/applicationShellSelectors';
import { ApplicationStatus } from '../../../applicationShell/helpers/constants/application';
import useInterval from '../../../../../hooks/useInterval';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';

export default function WelcomePanel() {
  const dispatch = useAppDispatch();
  const applicationStatus = useAppSelector(selectApplicationStatus);

  // User data doesn't updated immediately, so we need to poll BE.
  const poller = useCallback((id) => {
    if (applicationStatus !== ApplicationStatus.available) {
      dispatch(getUserData());
    } else {
      clearInterval(id);
    }
  }, [dispatch, applicationStatus]);

  useInterval(poller, 2000);

  return applicationStatus === ApplicationStatus.available
    ? <WelcomePanelComponent />
    : <BackdropLoading />;
}
