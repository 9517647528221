import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import PricingRedirector from '../routes/redirectors/Pricing';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Table from '../features/ui/userFarms/containers/Table';
import ToolsPanel from '../features/ui/userFarms/containers/ToolsPanel';
import TablePanel from '../features/ui/userFarms/containers/TablePanel';
import { PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';
import useDidMount from '../hooks/useDidMount';
import { selectSelectionMode } from '../features/ui/userFarms/userFarmsSelectors';

const UserFarms = () => {
  const { t } = useTranslation();
  const selectionMode = useSelector(selectSelectionMode);
  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.farms'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            route={PAGES_ROOTS.userFarms}
            headerProps={{
              text: t('user-farms.title'),
            }}
          >
            <ToolsPanel key="tools-panel" />
            { selectionMode && <TablePanel key="instrument-panel" /> }
            <Table key="panel" />
            { isFarmsFetching && <BackdropLoading key="loading" /> }
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default UserFarms;
