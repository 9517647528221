import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import {
  selectAreaUnit,
  selectLastOrder,
  selectMaxArea,
  selectTotalArea,
} from '../../../../user/userSelectors';
import { getEndDate, getOrderDaysLeft } from '../../../../user/helpers/functions/order';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import Button from '../../../../../components/Button';
import { getPricingAppUrl } from '../../../../../helpers/functions/utils/vendorConfigs/pricing';

import './index.scss';

const TrialPlanStatus = () => {
  const { t } = useTranslation();
  const areaUnit = useSelector(selectAreaUnit);
  const maxArea = useSelector(selectMaxArea);
  const totalArea = useSelector(selectTotalArea);
  const lastOrder = useSelector(selectLastOrder);
  const lastOrderEndDate = getEndDate(lastOrder);

  const daysLeft = useMemo(
    () => getOrderDaysLeft(lastOrderEndDate),
    [lastOrderEndDate],
  );

  return (
    <div className="trial-plan-status">
      <div className="trial-plan-status__item">
        <Trans i18nKey={'pricing.trial-plan.days-left'}>
          Text
          <span
            className="trial-plan-status__item-value trial-plan-status__item-value_highlighted"
          >
            {{ days: daysLeft }}
          </span>
        </Trans>
      </div>
      <div className="trial-plan-status__item">
        <Trans
          i18nKey={'pricing.trial-plan.used-area'}
          values={{
            areaUnit: getI18nAreaAmount(areaUnit),
            maxArea: convertNumberToFormattedString(maxArea.toFixed(2)),
            totalArea: convertNumberToFormattedString(totalArea.toFixed(2)),
          }}
        >
          Text
          <span
            className="trial-plan-status__item-value trial-plan-status__item-value_highlighted"
          >
            totalArea
          </span>
          <span className="trial-plan-status__item-value">
            maxArea
          </span>
        </Trans>
      </div>

      <Button
        classes={{
          root: 'trial-plan-status__upgrade-link',
          startIcon: 'trial-plan-status__upgrade-link-icon',
        }}
        startIcon={<TrendingUpIcon/>}
        href={getPricingAppUrl()}
        component="a"
        target="_blank"
        disabled={false}
      >
        {t('pricing.trial-plan.upgrade-plan')}
      </Button>
    </div>
  );
};

export default TrialPlanStatus;
