import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import UserDataRedirector from '../routes/redirectors/UserData';
import PricingRedirector from '../routes/redirectors/Pricing';
import Stepper from '../features/ui/batchAnalytics/containers/Stepper';
import {
  PAGES_ROOTS,
} from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { useAppDispatch } from '../app/store/helpers/functions';
import { resetSatelliteImages } from '../features/assets/assetsSlice';

export default function BatchAnalytics() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.batch-analytics'));

    return () => {
      dispatch(resetSatelliteImages());
    };
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            route={PAGES_ROOTS.batchAnalytics}
            headerProps={{
              text: t('general.navigation.batch-analytics'),
            }}
          >
            <Stepper key="panel"/>
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}
