import { API, graphqlOperation } from '@aws-amplify/api';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { DocumentNode } from 'graphql';

export const graphqlBaseQuery = (): BaseQueryFn<
{ document: string | DocumentNode; variables?: any },
unknown,
unknown | undefined,
{}
> => {
  return async ({ document, variables }) => {
    let result;

    try {
      result = await API.graphql(graphqlOperation(document, variables));
    } catch (error) {
      result = {
        error,
      };
    }

    return result;
  };
};

export const tryGetApiErrors = (error: unknown): Error[] => {
  let typedErrors: Error[];

  if (
    error
    && typeof error === 'object'
    && 'errors' in error
    && Array.isArray(error.errors)
  ) {
    typedErrors = error.errors as Error[];
  } else {
    typedErrors = [error] as [Error];
  }

  return typedErrors;
};
